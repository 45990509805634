/** @jsx jsx */
import { jsx, Button } from "theme-ui";
import React, { useCallback } from "react";
import { Modal } from "../Modal";
import useAlert from "./useAlert";

const style1 = {
  padding: "6px 10px",
  textAlign: "center",
  textDecoration: "none",
  display: "inline-block",
  fontSize: "16px",
  transitionDuration: "0.4s",
  cursor: "pointer",
  borderRadius: "3px",
  backgroundColor: "white",
  color: "black",
  border: "1px solid #247BA0",
  "&:hover": {
    backgroundColor: "#B6DEE2",
  },
};

const Alert = React.memo(() => {
  const { alert, showAlert } = useAlert();

  const dismissAlert = useCallback(() => showAlert(null), [showAlert]);

  if (alert === null || alert === undefined) {
    return null;
  }

  if (!alert.message) {
    return null;
  }
  const {
    title = "",
    cancelText = "OK",
    confirmText = null,
    confirmCallback = null,
    cancelCallback = dismissAlert,
    isBlocking = false,
    message,
  } = alert;

  // console.log("Alert", alert);

  return (
    <Modal handleClose={isBlocking ? null : cancelCallback} title={title}>
      <div style={{ padding: "10px 0" }}>{message}</div>
      <div style={{ float: "right" }}>
        {!!confirmText && !!confirmCallback && (
          <Button
            sx={style1}
            onClick={() => {
              cancelCallback();
              confirmCallback();
            }}
          >
            {confirmText}
          </Button>
        )}
        <Button sx={style1} onClick={cancelCallback}>
          {cancelText}{" "}
        </Button>
      </div>
    </Modal>
  );
});

export default Alert;
