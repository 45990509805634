import { createGlobalState } from "react-hooks-global-state";

const initialState = { alert: null };
const { useGlobalState } = createGlobalState(initialState);

const useAlert = () => {
  const [alert, setAlert] = useGlobalState("alert");

  return { showAlert: setAlert, alert };
};

export default useAlert;
