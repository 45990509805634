/** @jsx jsx */
import { jsx, useThemeUI, Button } from "theme-ui";
import React from "react";
import { tint } from "@theme-ui/color";
import PropTypes from "prop-types";

function lightOrDark(col) {
  let color = col;
  try {
    // Variables for red, green, blue values
    let r;
    let g;
    let b;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
      // If RGB --> store the red, green, blue values in separate variables
      const C = color.match(
        /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
      );

      [, r, g, b] = C;
    } else {
      // If hex --> Convert it to RGB: http://gist.github.com/983661
      color = +`0x${color.slice(1).replace(color.length < 5 && /./g, "$&$&")}`;

      r = color >> 16;
      g = (color >> 8) & 255;
      b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
      return "light";
    }
    return "dark";
  } catch (e) {
    return "light";
  }
}

const styles_modal_inner = {
  "top-right": {
    position: "fixed",
    background: "white",
    minWidth: "250px",
    height: "auto",
    top: "0",
    right: "0",
    padding: "8px",
    borderRadius: "3px",
  },
  right: {
    position: "fixed",
    background: "white",
    minWidth: "350px",
    maxWidth: "350px",
    overflowY: "auto",
    top: "0",
    right: "0",
    bottom: "0",
    padding: "8px",
    borderRadius: "1px",
  },
  mobile: {
    position: "fixed",
    maxHeight: "100vh",

    overflowX: "hidden",
    overflowY: "auto",
    bg: "background",
    "@media (max-width: 480px)": {
      width: "100%",
      margin: 0,
      height: "100vh",
    },
    "@media (min-width: 481px)": {
      left: "50%",
      transform: "translate(-50%)",
      borderRadius: "3px",
      width: "480px",
      margin: "auto",
    },
  },
  default: {
    position: "fixed",
    minWidth: "300px",
    height: "auto",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    padding: "8px",
    bg: "white",
  },
};

const style_closebtn = {
  backgroundColor: "transparent",
  border: "0",
  margin: "-4px -10px 0 15px",
  color: "#777",
  fontWeight: "bold",
  fontSize: "22px",
  lineHeight: "20px",
  cursor: "pointer",
  transition: "0.3s",
  "&:hover": {
    color: "black",
  },
};

const style_title = {
  backgroundColor: "transparent",
  border: "0",
  margin: "0 15px 0 0",
  fontWeight: "bold",
  display: "table-cell",
  fontSize: "20px",
  lineHeight: "20px",
};
const style_notitle = {
  margin: "0 15px 0 0",
  display: "table-cell",
};
const isBrowser = () => typeof window !== "undefined";

class ModalHandleBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = { height: null };
    this.setState = this.setState.bind(this);
  }

  componentDidMount() {
    if (isBrowser()) {
      const { height, overflow } = document.body.style;
      this.setState({ height, overflow });

      document.body.style.height = "100%";
      document.body.style.overflow = "hidden";
    }
  }

  componentWillUnmount() {
    if (isBrowser()) {
      const { height, overflow } = this.state;
      document.body.style.height = height;
      document.body.style.overflow = overflow;
    }
  }

  render() {
    return <div />;
  }
}

const stopPropagate = (e) => {
  e.stopPropagation();
};
const l = (b) => (lightOrDark(b) ? "rgba(1, 1, 1, 0.8)" : "rgba(0,0,0,0.8)");

export const Modal = ({
  position,
  handleClose,
  title,
  children,
  showCloseBtn,
  style_main,
  style_container,
}) => {
  const blockingClose = handleClose;
  const showClose = showCloseBtn && !!handleClose;
  const { theme = { colors: { background: "white" } } } = useThemeUI();
  const { colors = { background: "white" } } = theme;
  const { background = "white" } = colors;

  const style_modal = React.useMemo(
    () => ({
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      bg: position === "mobile" ? tint("secondary", 0.5) : l(background),
      textAlign: "left",
      zIndex: "100001",
    }),
    [background, position]
  );

  return (
    <div
      sx={style_modal}
      onClick={blockingClose}
      role="button"
      tabIndex="0"
      onKeyDown={blockingClose}
    >
      <ModalHandleBody />
      <section
        onClick={stopPropagate}
        role="button"
        tabIndex="0"
        onKeyDown={stopPropagate}
        sx={{ ...styles_modal_inner[position], ...style_main }}
      >
        {(!!title || !!showClose) && (
          <div style={{ display: "table", width: "100%", height: "24px" }}>
            {title ? (
              <span style={position === "mobile" ? style_notitle : style_title}>
                {title}
              </span>
            ) : (
              <span style={style_notitle}>
                <span>{children}</span>
              </span>
            )}
            {!!showClose && (
              <span style={{ display: "table-cell", textAlign: "right" }}>
                <Button sx={style_closebtn} onClick={handleClose}>
                  &times;
                </Button>
              </span>
            )}
          </div>
        )}
        <div sx={{ ...style_container }}>
          {((!!title && !!showClose) || !showClose) && (
            <div style={position === "mobile" ? { padding: "12px" } : {}}>
              {children}
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

Modal.propTypes = {
  position: PropTypes.oneOf(["default", "right", "top-right", "mobile"]),
  handleClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.element,
  showCloseBtn: PropTypes.bool,
  style_main: PropTypes.shape({}),
  style_container: PropTypes.shape({}),
};
Modal.defaultProps = {
  position: "default",
  handleClose: null,
  title: "",
  children: null,
  showCloseBtn: true,
  style_main: {},
  style_container: {},
};

export default Modal;
